import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { CtaSimple } from '../components/CTABlock';
import { LearnContent } from '../components/LearnContent';

export default function learnTemplate({ pageContext }) {
	const { markdownRemark } = pageContext; // data.markdownRemark holds your post data
	const { frontmatter, html } = markdownRemark;

	return (
		<Layout>
			<SEO pageTitle={frontmatter.title} description={frontmatter.description} />

			<LearnContent>
				<div className="text-gray-600 lg:px-4">
					<div className="py-4 mb-8 text-3xl font-light text-left text-gray-700 border-b border-gray-700 sm:text-4xl">
						{frontmatter.title}
					</div>
					{/* <div className="pt-2">
						<div className="inline-block px-4 py-1 my-2 mr-4 text-xs text-white bg-blue-400 rounded-full">
							{frontmatter.category}
						</div>
						<div className="inline-block px-4 py-2 my-2 text-sm lg:mx-4">
							{frontmatter.date}
						</div>
						<UserTag user={frontmatter.author} />
					</div>

					<img
						className="object-cover w-full my-20 h-96 rounded-xl"
						src={frontmatter.image}
					/> */}

					<div className="markdown" dangerouslySetInnerHTML={{ __html: html }} />
				</div>
			</LearnContent>

			<div className="mt-56">
				<CtaSimple eventPageName={`docs_${frontmatter.title}`} />
			</div>
		</Layout>
	);
}
